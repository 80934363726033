import React, { useEffect, useRef, useState } from "react";
import * as tf from "@tensorflow/tfjs";
import axios from "axios";
import "bulma/css/bulma.css";
import ReCAPTCHA from "react-google-recaptcha";

const list_of_authors = [
  "Kristy",
  "Nikayla",
  "Troy",
  "Mariah",
  "Susie",
  "Cristina",
  "Tori",
  "Frankie",
  "Gurman",
];

const error_dict_msg =
  "Sorry, but I can't guess based on this sentence! It's possible that non of the following words is in my dictionary :( Please try another one.";
const error_general_msg =
  "Sorry! But something went wrong :( Please try again.";

const p1 = "3qhnHkmfDR96J3oh4YI";
const p2 = "C3TZZPxHQh4Smjva9NoNm";

const min = 5;
const max = 20;
const google_ver =
  "674216813780-7a4860jb0evog7b89l4erfe6s6n5go6p.apps.googleusercontent.com";

const defaults = [
  [8, 0.7380087971687317],
  [2, 0.1121920496225357],
  [0, 0.08790941536426544],
  [1, 0.027311008423566818],
  [7, 0.024686507880687714],
  [3, 0.008755558170378208],
  [5, 0.00107420957647264],
  [4, 0.0000342858511430677],
  [6, 0.0000281752145383507],
];

const lbit = "F?fI";

const rot13 = (str) => {
  if (str === undefined) return "$";
  return str.replace(/[a-zA-Z]/g, function (c) {
    return String.fromCharCode(
      (c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26
    );
  });
};

function App() {
  const model = useRef(null);
  const [quote, setQuote] = useState("");
  const [results, setResults] = useState(defaults);
  const [loading, setLoading] = useState(false);
  const [words, setWords] = useState(0);
  const [resultReady, setResultReady] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [passCode, setPassCode] = useState("");
  const [captcha, setCaptcha] = useState(null);

  const modelLoading = useRef(null);
  const token = useRef(null);

  async function getModel() {
    modelLoading.current = true;
    const modelPromise = tf.loadLayersModel("/model.json");
    modelPromise.then((response) => {
      model.current = response;
      modelLoading.current = false;
    });
  }

  useEffect(() => {
    tf.setBackend("cpu");
  }, []);

  const tryAgain = () => {
    setResultReady(false);
    setQuote("");
    setWords(0);
  };

  const validate = () => {
    return words >= min && words <= max;
  };

  const predict = () => {
    if (!validate()) {
      return;
    }
    if (!loading) {
      setLoading(true);
    }
    if (modelLoading.current) {
      setTimeout(predict, 1000);
      return;
    }

    axios
      .post(
        "https://api.thegauntlet.ca/marvin",
        {
          data: quote.trim(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": p2 + p1,
            pass: passCode,
          },
        }
      )
      .then((res) => {
        const result = JSON.parse(res.data.body);
        if (result.length === 0) {
          setError(true);
          setErrorMsg(error_dict_msg);
          setLoading(false);
          return;
        }
        if (error) {
          setError(false);
        }
        let prediction = model.current.predict(tf.tensor([result]));
        const predictionPromise = prediction.data();
        predictionPromise.then((response) => {
          let results = response;
          results = Array.from(results);
          let sorted = results.map((item, index) => {
            return [index, item];
          });
          sorted.sort((first, second) => second[1] - first[1]);
          setResults(sorted);
          setLoading(false);
          setResultReady(true);
        });
      })
      .catch((res) => {
        if (!error) {
          setError(true);
        }
        setErrorMsg(error_general_msg);
      })
      .then(() => setLoading(false));
  };

  const updateQuote = (event) => {
    const current = event.target.value;
    setQuote(current);
    setWords(current.trim().split(/\s/).length);
  };

  const authenticate = () => {
    setProfileLoading(true);
    axios
      .post(
        "https://api.thegauntlet.ca/marvin/authenticate",

        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": p2 + p1,
            pass: passCode,
            token: token.current,
          },
        }
      )
      .then((res) => {
        setLoggedIn(true);
        getModel();
        if (accessDenied) {
          setAccessDenied(false);
        }
      })
      .catch((err) => {
        setAccessDenied(true);
      })
      .then(() => {
        setProfileLoading(false);
      });
  };

  const go = () => {
    if (passCode.length < 10) {
      setAccessDenied(true);
      return;
    }
    if (rot13(passCode.substring(0, 4)) !== lbit) {
      if (!accessDenied) {
        setAccessDenied(true);
      }
      return;
    }
    authenticate();
  };

  const onCaptcha = (value) => {
    token.current = value;
    setCaptcha(value);
  };

  return (
    <div className="container">
      <section className="hero is-info is-fullheight is-light">
        <div className="hero-body">
          <div className="container has-text-centered">
            <figure
              className="image is-128x128 has-text-centered"
              style={{ margin: "10px auto 0 auto" }}
            >
              <img src="/marvin.svg" style={{ display: "inline-block" }} />
            </figure>
            <h1
              className="subtitle"
              style={{
                marginBottom: 25,
                marginTop: 1,
                fontFamily: "monospace",
                fontSize: "1.1em",
              }}
            >
              Hi! My name is Marvin!
            </h1>
            {!loggedIn ? (
              <div className="has-text-centered">
                <input
                  className="input is-half has-text-centered"
                  style={{ width: 302, display: "inline-block" }}
                  value={passCode}
                  onChange={(event) => setPassCode(event.target.value)}
                  type="password"
                  placeholder="Passcode"
                />
                <button
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    marginRight: "auto",
                    marginLeft: "auto",
                    width: 302,
                  }}
                  onClick={go}
                  disabled={!captcha}
                  className={`is-fullwidth button is-primary ${
                    profileLoading ? "is-loading" : ""
                  }`}
                >
                  Go!
                </button>
                <div
                  style={{
                    width: 302,
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <ReCAPTCHA
                    sitekey="6Lf__aYZAAAAALqHg90mnWjCuAQ3eIFanP51CT36"
                    onChange={onCaptcha}
                    size="normal"
                  />
                </div>
              </div>
            ) : null}

            {accessDenied && !profileLoading ? (
              <p className="has-text-danger" style={{ marginTop: 10 }}>
                Access Denied!
              </p>
            ) : null}
            {loggedIn ? (
              <>
                {resultReady ? (
                  <>
                    <p
                      className="has-text-grey-lighter is-italic"
                      style={{ marginTop: 30, marginBottom: 4 }}
                    >
                      The author of
                    </p>
                    <p
                      className="is-italic"
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                        fontSize: "1.1em",
                      }}
                    >
                      {`"${quote.trim()}"`}
                    </p>
                    <p
                      className="has-text-grey-lighter is-italic"
                      style={{ marginTop: 4, marginBottom: 24 }}
                    >
                      is likely to be:
                    </p>
                  </>
                ) : null}

                {!resultReady ? (
                  <>
                    <div className="columns">
                      <div className="column is-half-tablet is-offset-one-quarter-tablet is-full-mobile">
                        <article
                          style={{ paddingTop: 0 }}
                          className="tile is-child notification  has-text-black is-half-tablet"
                        >
                          {error ? (
                            <p
                              className="has-text-danger has-text-left"
                              style={{ fontSize: 13 }}
                            >
                              {errorMsg}
                            </p>
                          ) : null}
                          <div className="content" id="notif-content">
                            <textarea
                              placeholder={`Gime me a sentence (${min}-${max} words)`}
                              value={quote}
                              onChange={updateQuote}
                            />
                          </div>
                          <div
                            className="has-text-right"
                            style={{ marginTop: "-4px" }}
                          >
                            <span
                              style={{ fontSize: 14 }}
                              className={`${
                                validate()
                                  ? "has-text-primary"
                                  : "has-text-danger"
                              }`}
                            >
                              {words} words
                            </span>
                          </div>
                        </article>
                      </div>
                    </div>

                    <button
                      id="predict-button"
                      className={`button is-link is-light is-small  ${
                        loading ? "is-loading" : ""
                      }`}
                      disabled={!validate()}
                      onClick={predict}
                    >
                      Who's likely the author?
                    </button>
                  </>
                ) : null}

                {resultReady ? (
                  <>
                    <div id="columns">
                      {results.map((item, index) => (
                        <div
                          className={`result column is-one-third-tablet is-offset-one-third-tablet is-10-mobile is-offset-1-mobile  ${list_of_authors[
                            item[0]
                          ].toLowerCase()}`}
                          key={index}
                        >
                          <span
                            className="result-bar"
                            style={{ width: item[1] * 100 + "%" }}
                          ></span>
                          <span className="result-value ">
                            {list_of_authors[item[0]]}:{" "}
                            {(item[1] * 100).toFixed(2) + "%"}
                          </span>
                        </div>
                      ))}
                    </div>
                    <button
                      style={{ marginTop: 20 }}
                      className={`button is-info is-light is-small  ${
                        loading ? "is-loading" : ""
                      }`}
                      onClick={tryAgain}
                    >
                      Try another one
                    </button>
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
